import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import ReactPlayer from 'react-player'
import {
  VideoCameraOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Button, Modal, DatePicker, Form, Card, Tooltip, Typography } from 'components'

const IconPack = {
  VideoCameraOutlined: <VideoCameraOutlined />,
  CalendarOutlined: <CalendarOutlined />,
  InfoCircleOutlined: <InfoCircleOutlined />,
}

const dueDateRules = [
  {
    required: true,
    message: 'Please provide due date',
  },
]

const Description = ({ children }) => (
  <Typography.Paragraph ellipsis={{ rows: 4 }}>{children}</Typography.Paragraph>
)

const ResourceStep = props => {
  const [ activeModalAction, setActiveModalAction ] = useState({})
  const [ isModalVisible, setIsModalVisible ] = useState(false)

  const renderActionContent = ({ type, content, eventTemplateId }) => {
    if (type === 'text') {
      return (
        <div>
          {content}
        </div>
      )
    }

    if (type === 'html') {
      return (
        <>
          <div className="content-type-html-content" dangerouslySetInnerHTML={{__html: content}}/>
        </>
      )
    }

    if (type === 'image') {
      return (
        <>
          <img alt="" style={{ width: '100%' }} src={content}/>
        </>
      )
    }

    if (type === 'video') {
      return (
        <>
          <ReactPlayer
            width="100%"
            url={content}
          />
        </>
      )
    }

    if (type === 'event') {
      const onFormSubmit = values => {
        const payload = {
          eventTemplateId,
          date: (new Date()).toISOString(),
        }

        props.eventStore.createEvent(payload)
      }

      return (
        <div>
          <Form id="event-form" onFinish={onFormSubmit}>
            <Form.Item label="Due date" name="date" rules={dueDateRules}>
              <DatePicker />
            </Form.Item>
            <Button htmlType="submit">
              Submit
            </Button>
          </Form>
        </div>
      )
    }
  }

  const renderActionModalTitle = () => (
    <div>
      {`${props.index}. ${props.title}`}
    </div>
  )

  const returnActions = props => {
    const actions = [];

    if (props.resourceActions?.length) {
      actions.push(
        ...props.resourceActions.map(action => (
          <Button
            onClick={() => onActionButtonClick(action)}
            type={action.color}
            key={action.label}
          >
            {action.label || 'Learn More'}
          </Button>
        ))
      );
    }

    if (props.resourceSteps?.length > 0) {
      actions.push(
        <Button type="primary" key="drill-in-button">
          <Link to={`${props?.match?.url}/${props.id}`}>
            {props.drillInButtonName || 'Default'}
          </Link>
        </Button>
      );
    }

    return actions;
  }

  const onActionButtonClick = action => {
    if (action.moreInfoLink) {
      window.open(action.moreInfoLink, '_blank')
    } else {
      setIsModalVisible(true)
      setActiveModalAction(action)
    }
  }

  return (
    <>
      <Modal
        title={renderActionModalTitle()}
        visible={isModalVisible}
        footer={[
          <Button
            type="primary"
            children="Back"
            onClick={() => setIsModalVisible(false)}
          />
        ]}
        closable={false}
        afterClose={() => setActiveModalAction({})}
        destroyOnClose
        onCancel={() => setIsModalVisible(false)}
      >
        {renderActionContent(activeModalAction)}
      </Modal>
      <Card
        cover={props.imageUrl && <img alt="image" src={props.imageUrl} />}
        actions={returnActions(props)}
      >
        <Card.Meta
          title={<Tooltip placement="topLeft" title={props.title}>{props.title}</Tooltip>}
          description={<Description>{props.description}</Description>}
        />
      </Card>
    </>
  )
}

export default inject('eventStore')(observer (ResourceStep))
