import React from 'react'
import { accountTileGenericContainerStyles } from './styles.module.scss'
import { Breadcrumb, Button, TileNavigator } from 'components'
import * as APP_PATHS from 'utils/path-helpers/app'
import { observer, inject } from 'mobx-react'
import { openSocialLoginWindow } from 'utils/auth'
import { message } from 'utils/notifications'
import govxLogo from 'static/img/govxLogo.png'

const DiscountContainer = props => {
  const onVerification = (data, redirectURI) => {
    if (data && data.code) {
      props.userDataStore.verifyVeteran({
        code: data.code,
        redirectUri: redirectURI,
      })
    }
  }

  const onGovXVerifyClick = () => {
    openSocialLoginWindow('govx', onVerification, message.error)
  }

  const tiles = [
    {
      label: (
        <div>
          <img src={govxLogo} />
          <br/>
          <Button> Verify Me </Button>
        </div>
      ),
      onClick: onGovXVerifyClick,
    },
  ]

  return (
    <div className={accountTileGenericContainerStyles}>
      <Breadcrumb
        className="account-tile-breadcrumb"
        label="Back"
        to={APP_PATHS.accountSubscriptionPath()}
      />
      <h3>Savings for Those that Serve!</h3>
      <p>As a Veteran Owned Business, BreakTurn Academy is proud to offer our Premium Subscription free to all current and former members of the U.S. Military. This includes active duty, reserve, veteran, retired and ROTC personnel.</p>
      <TileNavigator tiles={tiles} />
    </div>
  )
}

export default inject('userDataStore')(observer (DiscountContainer))
