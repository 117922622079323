import React from 'react'
import { observer, inject } from 'mobx-react'
import { HomeOutlined } from '@ant-design/icons'
import { resourcesContainerStyles } from './styles.module.scss'
import { Breadcrumb, LinearBreadcrumb, Link, PlaneLoader, ResourceStep } from 'components'
import { getHigherLevelPath } from 'utils/path-helpers'
import { resourceModulePath, resourcesPath } from 'utils/path-helpers/app'

const returnLinearBreadcrumbs = ({ match, activeModuleId, resourcesStore }) => {
  let viewStep = {}

  if (match.params.viewDataId) {
    if (activeModuleId !== match.params.viewDataId) {
      viewStep = resourcesStore.stepInModuleById(activeModuleId, match.params.viewDataId) || {}
    } else {
      viewStep = resourcesStore.moduleById(activeModuleId) || {}
    }
  }

  if (!viewStep.resourceSteps) return null

  return (
    <>
      <LinearBreadcrumb.Item>
        <Link to={resourcesPath()}>
          <HomeOutlined />
        </Link>
      </LinearBreadcrumb.Item>

      {viewStep.stepsUntilModule.map((stepOrModule, index) => (
        <LinearBreadcrumb.Item key={index}>
          <Link to={resourceModulePath(stepOrModule.idPath)}>
            {stepOrModule.title}
          </Link>
        </LinearBreadcrumb.Item>
      ))}
    </>
  )
}

const returnBreadcrumb = props => {
  if (!props.activeModuleId) return null

  return (
    <>
      <Breadcrumb
        to={getHigherLevelPath(props.match.url)}
      />
      <LinearBreadcrumb>
        {returnLinearBreadcrumbs(props)}
      </LinearBreadcrumb>
    </>
  )
}

const returnViewSteps = props => {
  let viewModuleOrStep = {}
  const { match, activeModuleId, resourcesStore } = props

  if (match.params.viewDataId) {
    if (activeModuleId === match.params.viewDataId) {
      viewModuleOrStep = resourcesStore.moduleById(match.params.viewDataId) || {}
    } else {
      viewModuleOrStep = resourcesStore.stepInModuleById(activeModuleId, match.params.viewDataId) || {}
    }

    if (!viewModuleOrStep.resourceSteps) {
      viewModuleOrStep = resourcesStore.modules
    }
  }

  const stepsToRender = viewModuleOrStep.length ? viewModuleOrStep : viewModuleOrStep.resourceSteps

  if (!stepsToRender) return null

  return stepsToRender.map((viewData, index) => (
    <ResourceStep
      {...viewData}
      index={index + 1}
      key={index}
      stepReference={viewData}
      viewModuleOrStep={viewModuleOrStep}
      match={props.match}
      activeModuleId={props.activeModuleId}
    />
  ))
}

const returnModuleShortDescription = props => {
  if (props.activeModuleId) return null

  return (
    <h2 className="resources-title">
      {props.resourcesStore.getTitle()}
    </h2>
  )
}

const ResourcesContainer = props => {
  return (
    <PlaneLoader loading={props.resourcesStore.isLoading}>
      <div className={resourcesContainerStyles}>
        <div className="resources-title-wrapper">
          {returnBreadcrumb(props)}
          {returnModuleShortDescription(props)}
        </div>

        <div className="resources-steps">
          {returnViewSteps(props)}
        </div>
      </div>
    </PlaneLoader>
  )
}

export default inject('masterStore')(inject('resourcesStore')(observer(ResourcesContainer)))
