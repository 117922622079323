import { types, flow } from "mobx-state-tree"
import { navigateToPathways } from 'utils/navigation'
import * as stores from 'stores'
import { accessToken } from 'utils/auth'

const MasterStore = types
  .model({})
  .actions(self => ({
    onInit: flow(function*() {
      if(accessToken.get()) {
        stores.userDataStore.getCurrentUser()
        stores.eventStore.getEventTemplates()
        stores.resourcesStore.getResources()
        stores.gearSavingsStore.getGearSavings()

        const survey = yield stores.surveyStore.getSurveyUnit()
        if (stores.surveyStore.unit.id) navigateToPathways()
        yield stores.careerStore.getDataInitially()

        // TODO: no need to optimize for prod purposes but only for dev...
        // if (window.env !== 'prod') stores.careerStore.getMasterPaths()
      }
    }),
    onAfterRetake: flow(function*() {
      stores.careerStore.resetStoreData()
      stores.surveyStore.getSurveyUnit()

      navigateToPathways()
    }),
  }))

export const masterStore = MasterStore.create()
