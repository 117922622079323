import PPInput from './Input'
import PPInputPassword from './InputPassword'
import PPInputSearch from './InputSearch'
import PPTextArea from './TextArea'

PPInput.Password = PPInputPassword
PPInput.Search = PPInputSearch
PPInput.TextArea = PPTextArea

export default PPInput
