import React from 'react'
import { Menu } from 'components'
import { asideMenuContainerStyles, asideMenuPageBlockerStyles } from './styles.module.scss'
import * as paths from 'utils/path-helpers/app'
import {
  PartitionOutlined,
  LineChartOutlined,
  CalendarOutlined,
  DollarCircleOutlined,
  LockOutlined,
  FilePdfOutlined,
  SnippetsOutlined,
  IdcardOutlined,
  TrophyOutlined,
  SolutionOutlined,
  InboxOutlined,
  FolderOpenOutlined
} from '@ant-design/icons'
import { navigateTo } from 'utils/navigation'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { isFlexAirInstance } from 'constants/instances'

const asideMenuItemsData = [
  {
    key: paths.pathwaysPath(),
    icon: <PartitionOutlined />,
    label: 'Pathways',
  },
  {
    key: paths.preferredPathwaysPath(),
    icon: <PartitionOutlined />,
    label: 'Preferred Pathways',
    hidden: !isFlexAirInstance,
  },
  {
    key: paths.careerPath(),
    icon: <LineChartOutlined />,
    label: 'Career Planner',
  },
  {
    key: paths.timelinePath(),
    icon: <CalendarOutlined />,
    label: 'Timeline Assistant',
  },
  {
    key: paths.scholarshipsPath(),
    icon: <TrophyOutlined />,
    label: 'Scholarship Finder',
  },
  {
    key: paths.forecasterPath(),
    icon: <DollarCircleOutlined />,
    label: 'Earnings Forecaster',
    hidden: true,
  },
  {
    key: paths.resourcesPath(),
    icon: <FolderOpenOutlined />,
    label: 'Resources',
  },
  {
    key: paths.gearSavingsPath(),
    icon: <InboxOutlined />,
    label: 'Gear & Savings',
  },
  {
    key: paths.flightSchoolFinderPath(),
    label: 'Flight School Finder',
    external: true
  },
  {
    key: paths.consultingPath(),
    icon: <SolutionOutlined />,
    label: 'Consulting',
    hidden: true,
  },
  {
    key: paths.vaultPath(),
    icon: <LockOutlined />,
    label: 'Application Vault',
    hidden: true,
  },
  {
    key: paths.builderPath(),
    icon: <FilePdfOutlined />,
    label: 'Resume Builder',
    hidden: true,
  },
  {
    key: paths.resumePath(),
    icon: <SnippetsOutlined />,
    label: 'Resume Bot',
    hidden: true,
  },
  {
    key: paths.interviewPath(),
    icon: <IdcardOutlined />,
    label: 'Interview Prep',
    hidden: true,
  },
]

const returnAsideMenuItems = () => asideMenuItemsData.map(item => item.hidden ? null : (
  <Menu.Item key={item.key}>
    {item.icon}
    {item.label}
  </Menu.Item>
))

const returnAdaptiveBackground = props => {
  const blockerClass = classNames({
    [asideMenuPageBlockerStyles]: true,
    'visible': props.adaptiveStore.isAsideMenuVisible,
  })

  return (
    <div
      className={blockerClass}
      onClick={props.adaptiveStore.hideAsideMenu}
    />
  )
}

const ConfirmationContainer = props => {
  const onMenuItemSelect = ({ key }) => {
    const selectedItem = asideMenuItemsData.find(item => item.key === key)

    if (selectedItem.external) {
      window.open(key, '_blank')
    } else {
      props.adaptiveStore.hideAsideMenu()
      navigateTo(key)
    }
  }

  const containerClass = classNames({
    [asideMenuContainerStyles]: true,
    'visible': props.adaptiveStore.isAsideMenuVisible,
  })

  return (
  <>
    <aside className={containerClass}>
      <Menu onSelect={onMenuItemSelect} selectedKeys={[ props.match.url ]}>
        {returnAsideMenuItems()}
      </Menu>
    </aside>
    {returnAdaptiveBackground(props)}
  </>
  )
}

export default inject('adaptiveStore')(observer (ConfirmationContainer))
