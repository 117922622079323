import React from 'react'
import { observer, inject } from 'mobx-react'
import { HomeOutlined } from '@ant-design/icons'
import { gearSavingsContainerStyles } from './styles.module.scss'
import { Alert, Breadcrumb, Button, LinearBreadcrumb, Link, GearSavingStep, PlaneLoader } from 'components'
import { message } from 'utils/notifications'
import { openSocialLoginWindow } from 'utils/auth'
import { getHigherLevelPath } from 'utils/path-helpers'
import { gearSavingModulePath, gearSavingsPath } from 'utils/path-helpers/app'
import govxLogo from 'static/img/govxLogo.png'

const returnLinearBreadcrumbs = ({ match, activeModuleId, gearSavingsStore }) => {
  let viewStep = {}

  if (match.params.viewDataId) {
    if (activeModuleId !== match.params.viewDataId) {
      viewStep = gearSavingsStore.stepInModuleById(activeModuleId, match.params.viewDataId) || {}
    } else {
      viewStep = gearSavingsStore.moduleById(activeModuleId) || {}
    }
  }

  if (!viewStep.gearSavingSteps) return null

  return (
    <>
      <LinearBreadcrumb.Item>
        <Link to={gearSavingsPath()}>
          <HomeOutlined />
        </Link>
      </LinearBreadcrumb.Item>

      {viewStep.stepsUntilModule.map((stepOrModule, index) => (
        <LinearBreadcrumb.Item key={index}>
          <Link to={gearSavingModulePath(stepOrModule.idPath)}>
            {stepOrModule.title}
          </Link>
        </LinearBreadcrumb.Item>
      ))}
    </>
  )
}

const returnBreadcrumb = props => {
  if (!props.activeModuleId) return null

  return (
    <>
      <Breadcrumb
        to={getHigherLevelPath(props.match.url)}
      />
      <LinearBreadcrumb>
        {returnLinearBreadcrumbs(props)}
      </LinearBreadcrumb>
    </>
  )
}

const returnViewSteps = props => {
  let viewModuleOrStep = {}
  const { match, activeModuleId, gearSavingsStore } = props

  if (match.params.viewDataId) {
    if (activeModuleId === match.params.viewDataId) {
      viewModuleOrStep = gearSavingsStore.moduleById(match.params.viewDataId) || {}
    } else {
      viewModuleOrStep = gearSavingsStore.stepInModuleById(activeModuleId, match.params.viewDataId) || {}
    }

    if (!viewModuleOrStep.gearSavingSteps) {
      viewModuleOrStep = gearSavingsStore.modules
    }
  }

  const stepsToRender = viewModuleOrStep.length ? viewModuleOrStep : viewModuleOrStep.gearSavingSteps

  if (!stepsToRender) return null

  return stepsToRender.map((viewData, index) => (
    <GearSavingStep
      {...viewData}
      index={index + 1}
      key={index}
      stepReference={viewData}
      viewModuleOrStep={viewModuleOrStep}
      match={props.match}
      activeModuleId={props.activeModuleId}
    />
  ))
}

const returnModuleShortDescription = props => {
  if (props.activeModuleId) return null

  return (
    <h2 className="gear-savings-title">
      {props.gearSavingsStore.getTitle()}
    </h2>
  )
}

const returnDiscountBanner = props => {
  const onVerification = (data, redirectURI) => {
    if (data && data.code) {
      props.userDataStore.verifyVeteran({
        code: data.code,
        redirectUri: redirectURI,
      })
    }
  }

  const onGovXVerifyClick = () => {
    openSocialLoginWindow('govx', onVerification, message.error)
  }

  if (props.userDataStore.isStripeSubscriptionActive) return null

  return (
    <div className="gear-savings-banner-wrapper">
      <Alert
        message={
          <>
            <p>As a Veteran Owned Business, BreakTurn Academy is proud to offer our Premium Subscription free to all
              current and former members of the U.S. Military. This includes active duty, reserve, veteran, retired and
              ROTC personnel.</p>
            <div className="gear-savings-banner-actions">
              <img src={govxLogo} width="150px" alt="GovX Logo" />
              <Button onClick={onGovXVerifyClick} size="small" type="primary">
                Verify Me
              </Button>
            </div>
          </>
        }
        type="info"
      />
    </div>
  )
}

const GearSavingsContainer = props => {
  return (
    <PlaneLoader loading={props.gearSavingsStore.isLoading}>
      <div className={gearSavingsContainerStyles}>
        {!props.userDataStore.isSubscriptionInfoLoading && returnDiscountBanner(props)}

        <div className="gear-savings-title-wrapper">
          {returnBreadcrumb(props)}
          {returnModuleShortDescription(props)}
        </div>

        <div className="gear-savings-steps">
          {returnViewSteps(props)}
        </div>
      </div>
    </PlaneLoader>
  )
}

export default inject('masterStore')(inject('userDataStore')(inject('gearSavingsStore')(observer(GearSavingsContainer))))
