// pages for unregistered user
export const authPath = () => '/auth'
export const registrationPath = () => `${authPath()}/registration`
export const confirmationPath = () => `${authPath()}/confirmation`
export const authPasswordPath = () => `${authPath()}/password`
export const authNewPasswordPath = () => `${authPasswordPath()}/new`

// pages for registered user
export const appPath = () => '/app'
export const appModulePath = (module = ':module') => `${appPath()}/${module}`
export const pathwaysPath = () => `${appModulePath('pathways')}`
export const preferredPathwaysPath = () => `${appModulePath('preferred-pathways')}`
export const timelinePath = () => `${appModulePath('timeline')}`
export const forecasterPath = () => `${appModulePath('forecaster')}`
export const scholarshipsPath = () => `${appModulePath('scholarships')}`
export const resourcesPath = () => `${appModulePath('resources')}`
export const resourceModulePath = (moduleId = ':moduleId?') => `${resourcesPath()}/${moduleId}`
export const gearSavingsPath = () => `${appModulePath('gear-savings')}`
export const gearSavingModulePath = (moduleId = ':moduleId?') => `${gearSavingsPath()}/${moduleId}`
export const consultingPath = () => `${appModulePath('consulting')}`
export const consultingCategoryPath = (categoryId = ':categoryId?') => `${consultingPath()}/${categoryId}`
export const vaultPath = () => `${appModulePath('vault')}`
export const builderPath = () => `${appModulePath('builder')}`
export const resumePath = () => `${appModulePath('resume')}`
export const interviewPath = () => `${appModulePath('interview')}`
export const careerPath = () => `${appModulePath('career')}`
export const careerSurveyPath = () => `${careerPath()}/survey`
export const careerPathwayPath = () => `${careerPath()}/pathway`
export const careerPathwaySelectionPath = () => `${careerPathwayPath()}/selection`
export const careerPathwayModulesPath = () => `${careerPathwayPath()}/modules`
export const careerPathwayModulePath = (moduleId = ':moduleId?') => `${careerPathwayModulesPath()}/${moduleId}`
export const accountPath = () => `${appModulePath('account')}`
export const accountUserPath = () => `${accountPath()}/user`
export const accountPasswordPath = () => `${accountPath()}/password`
export const accountContactPath = () => `${accountPath()}/contact`

export const accountSubscriptionPath = () => `${accountPath()}/subscription`
export const upgradeSubscriptionPath = () => `${accountSubscriptionPath()}/upgrade`
export const discountSubscriptionPath = () => `${accountSubscriptionPath()}/discount`

// pages for admin
export const adminPath = () => '/admin'
export const adminEditorPath = () => `${adminPath()}/editor`

// pages for various technical stuff
export const technicalPath = () => '/technical'
export const technicalSocialAuthPath = () => `${technicalPath()}/social-auth`

// external navigation
export const flightSchoolFinderPath = () => 'https://pilotpipeline.com/flight-school-finder/'