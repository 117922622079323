// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__resourcesContainerStyles___bUvrn{width:100%;flex-grow:1}.styles-module__resourcesContainerStyles___bUvrn .resources-title-wrapper{display:flex;flex-direction:row;align-items:center;margin-bottom:20px}.styles-module__resourcesContainerStyles___bUvrn .resources-title-wrapper .ant-breadcrumb{display:inline-block;padding-left:20px;font-size:16px}.styles-module__resourcesContainerStyles___bUvrn .resources-title{text-align:center;margin-bottom:0;width:100%;line-height:36px}.styles-module__resourcesContainerStyles___bUvrn .resources-steps{display:grid;grid-gap:20px;grid-template-columns:repeat(auto-fill, minmax(240px, 1fr));grid-auto-rows:400px}.styles-module__resourcesContainerStyles___bUvrn .resources-steps .ant-card-actions a{color:#fff}.styles-module__resourcesContainerStyles___bUvrn .resources-steps .ant-card-actions a:hover{color:#fff}\n", ""]);
// Exports
exports.locals = {
	"resourcesContainerStyles": "styles-module__resourcesContainerStyles___bUvrn"
};
module.exports = exports;
