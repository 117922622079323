import { flow, getType, resolvePath, types } from 'mobx-state-tree'
import axios from 'axios'
import { message } from 'utils/notifications'
import * as paths from 'utils/path-helpers/api'

export const findStepById = (steps, id) => {
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].id === id) return steps[i]
  }

  for (let i = 0; i < steps.length; i++) {
    if (steps[i].gearSavingSteps.length) {
      const step = findStepById(steps[i].gearSavingSteps, id)
      if (step) return step
    }
  }
}

export const findModuleById = (modules, id) => {
  return modules.find(module => module.id === id)
}

const Action = types
  .model('Action', {
    id: types.identifier,
    label: types.optional(types.string, ''),
    color: types.enumeration(['primary', 'secondary']),
    icon: types.optional(types.string, ''),
    type: types.enumeration(['default', 'text', 'html', 'image', 'video', 'event']),
    content: types.optional(types.string, ''),
    eventTemplateId: types.optional(types.string, ''),
    subButtonName: types.optional(types.string, ''),
    trackableForRequest: types.optional(types.boolean, false),
    moreInfoLink: types.maybeNull(types.string)
  })

const Step = types
  .model('Step', {
    id: types.identifier,
    title: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    drillInButtonName: types.optional(types.string, ''),
    imageUrl: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
    gearSavingActions: types.optional(types.array(types.late(() => Action)), []),
    gearSavingSteps: types.optional(types.array(types.late(() => Step)), []),
  })
  .views(self => ({
    get stepsUntilModule() {
      let items = []
      let stepOrModule = self

      items.unshift(stepOrModule)

      do {
        stepOrModule = resolvePath(stepOrModule, '../..')
        items.unshift(stepOrModule)
      } while (getType(stepOrModule).name === 'Step')

      return items
    },
    get idPath() {
      return self.stepsUntilModule.map(item => item.id).join('/')
    }
  }))

const Module = types
  .model('Module', {
    id: types.identifier,
    title: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    shortDescription: types.optional(types.string, ''),
    drillInButtonName: types.optional(types.string, ''),
    imageUrl: types.optional(types.string, ''),
    gearSavingActions: types.optional(types.array(Action), []),
    gearSavingSteps: types.optional(types.array(Step), []),
  }).views(self => ({
    get idPath() {
      return self.id
    },
    get stepsUntilModule() {
      return [self]
    }
  }))

const GearSavingsStore = types
  .model('GearSavingsStore', {
    title: types.optional(types.string, 'Gear & Savings'),
    modules: types.optional(types.array(Module), []),
    isLoading: true
  })
  .views((self) => ({
    getTitle() {
      return self.title
    },
    moduleById(id) {
      return findModuleById(self.modules, id)
    },
    stepInModuleById(moduleId, stepId) {
      const module = findModuleById(self.modules, moduleId)

      if (module) {
        return findStepById(module.gearSavingSteps, stepId)
      }

      return undefined
    }
  }))
  .actions((self) => ({
    getGearSavings: flow(function*() {
      self.isLoading = true
      try {
        const response = yield axios.get(paths.gearSavingPath())
        self.modules = response.data
      } catch (error) {
        message.error(error.response.data.message)
      }
      self.isLoading = false
    }),
  }))

export const gearSavingsStore = GearSavingsStore.create()