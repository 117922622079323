import React from 'react'
import { Input } from 'antd'
import './styles.scss'

const PPTextArea = props => (
  <Input.TextArea {...props} className={`custom-text-area ${props.className || ''}`} />
)

export default PPTextArea

