// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__gearSavingsContainerStyles___f_1wT{width:100%;flex-grow:1}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-title-wrapper{display:flex;flex-direction:row;align-items:center;margin-bottom:20px}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-title-wrapper .ant-breadcrumb{display:inline-block;padding-left:20px;font-size:16px}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-title{text-align:center;margin-bottom:0;width:100%;line-height:36px}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-steps{display:grid;grid-gap:20px;grid-template-columns:repeat(auto-fill, minmax(240px, 1fr));grid-auto-rows:400px}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-steps .ant-card-actions a{color:#fff}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-steps .ant-card-actions a:hover{color:#fff}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-banner-wrapper{margin-bottom:16px}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-banner-wrapper .ant-alert-message{display:flex;flex-direction:row;justify-content:space-between}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-banner-wrapper p{margin:0}.styles-module__gearSavingsContainerStyles___f_1wT .gear-savings-banner-actions{display:flex;align-items:center;flex-direction:column;margin-left:8px}\n", ""]);
// Exports
exports.locals = {
	"gearSavingsContainerStyles": "styles-module__gearSavingsContainerStyles___f_1wT"
};
module.exports = exports;
